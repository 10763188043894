<template>
  <v-dialog
    v-model="dialog"
    :persistent="!closed"
    max-width="290"
    height="290"
    style="background: #1E1E1E!important;"
  >
    <v-card
      dark
      color="black"
      style="background: #1E1E1E!important;"
    >
      <v-card-title class="display-1 mb-2">
        <div class="notify-title">{{ title }}</div>
      </v-card-title>
      <div class="notify-box" v-html="replaceWithBr()">
        {{ content }}
      </div>
      <v-card-actions class="pa-2 justify-center">
        <!-- <v-spacer /> -->
        <v-btn
          :loading="loading"
          color="purple"
          @click="okHandleEvent"
        >
          {{ okText }}
        </v-btn>
        <v-btn
          v-show="showCancel"
          color="purple"
          text
          @click="cancelHandleEvent"
        >
          {{ cancelText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OverDialog',
  props: {
    okHandle: {
      type: Function,
      default: () => {

      }
    },
    cancelHandle: {
      type: Function,
      default: () => {

      }
    }
  },
  data() {
    return {
      dialog: false,
      title: 'Information for you',
      content: '',
      okText: 'OK',
      cancelText: 'Cancel',
      loading: false,
      closed: false,
      showCancel: true
    }
  },
  methods: {
    replaceWithBr() {
      return this.content.replace(/\n/g, '<br />')
    },
    controlDialog(dialog, content, title = 'Information for you', closed = false, showCancel = true, okText = this.okText, cancelText = this.cancelText) {
      this.content = content
      this.title = title
      this.closed = closed
      this.showCancel = showCancel
      this.dialog = true
      this.okText = okText
      this.cancelText = cancelText
    },
    okHandleEvent() {
      if (this.closed) {
        this.dialog = false
        return
      }
      this.loading = true
      this.okHandle('active')
    },
    cancelHandleEvent() {
      if (this.loading) {
        return
      }
      this.cancelHandle()
      this.controlShowDialog(false)
    },
    controlLoading(loading) {
      this.loading = loading
    },
    controlShowDialog(dialog) {
      this.dialog = dialog
    }
  }
}
</script>

<style lang="scss" scoped>
.notify-box{
    margin: 10px;
}
</style>
